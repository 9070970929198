import { createClient } from 'contentful';

import { StateAbbreviation } from '../types';
import {
  Authority,
  ContentfulElection,
  ElectionAuthorityData,
  HelpContent,
  HelpContentType,
  HelpResource,
  RegistrationMethod,
  VotingMethodData,
  VotingMethodType,
} from '../types/contentful';

interface DWorksEntry<T> {
  data: T;
  endpoint: string;
}

let locale = 'en-US';

let client = {
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_STAGING_CONTETNFUL_TOKEN,
  environment: process.env.REACT_APP_STAGING_CONTENTFUL_ENV,
  retryOnError: false,
};

if (process.env.NODE_ENV === 'production') {
  client = {
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_PRODUCTION_CONTENTFUL_TOKEN,
    environment: process.env.REACT_APP_PRODUCTION_CONTENTFUL_ENV,
    retryOnError: false,
  };
}

const contentful = createClient(client);

const getContentfulEntries = async <T>(type: string, query: object = {}) => {
  try {
    const res = await contentful.getEntries<T>({
      locale,
      content_type: type,
      ...query,
    });

    return res.items;
  } catch (error) {
    console.error(error);

    return [];
  }
};

const getDWorksResponse = async <T>(
  endpoint: string,
): Promise<DWorksEntry<T> | null> => {
  try {
    const res = await contentful.getEntries<DWorksEntry<T>>({
      locale,
      content_type: 'democracyWorks',
      limit: 1,
      order: '-sys.createdAt',
      'fields.endpoint': endpoint,
    });

    return res.items && res.items[0].fields ? res.items[0].fields : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getHelpContent = async (
  type: HelpContentType,
): Promise<HelpContent[]> => {
  const res = await getContentfulEntries<HelpContent>('helpContent', {
    'fields.type': type,
    order: 'fields.order',
  });

  return res.map((r) => r.fields);
};

const getHelpResources = async (
  state: StateAbbreviation,
): Promise<HelpResource[]> => {
  const res = await getContentfulEntries<HelpResource>('resourceLink', {
    'fields.stateCode': state,
    order: 'fields.order',
  });

  return res.map((r) => r.fields);
};

export interface ParsedAuthority extends Authority {
  voterRegistrationStatusUrl?: string;
}

const getElectionAuthority = async (
  ocdId: string,
): Promise<ParsedAuthority | {}> => {
  const item = await getDWorksResponse<ElectionAuthorityData>(
    '/election-authorities/states',
  );

  if (!item) {
    return {};
  }

  const { authorities } = item.data;

  const authority = authorities.find((a) => a['ocd-division'][0] === ocdId);

  if (!authority) {
    return {};
  }

  return {
    ...authority,
    voterRegistrationStatusUrl:
      authority['ocd-division'][2]['voter-registration-status-url'],
  };
};

export interface VotingAndRegistrationMethods {
  votingMethods: VotingMethodData[];
  registrationMethods: RegistrationMethod[];
  electionDate?: string;
}

const getVotingAndRegistrationMethods = async (
  ocdId: string,
): Promise<VotingAndRegistrationMethods> => {
  let votingMethods: VotingMethodData[] = [];
  let registrationMethods: RegistrationMethod[] = [];

  const endpoint = `/elections/upcoming?language=all&district-divisions=${ocdId}`;
  const item = await getDWorksResponse<ContentfulElection[]>(endpoint);

  if (!item || item.data.length < 1) {
    return { votingMethods, registrationMethods };
  }

  const date = item.data[0].date;
  const division = item.data[0]['district-divisions'][0];
  const regJson = division['voter-registration-methods'];
  const voteJson = division['voting-methods'];

  const electionDate = new Date(date).toLocaleString();

  registrationMethods = regJson.map((r) => ({ ...r, electionDate }));

  votingMethods = voteJson.map((v) => ({
    ...v,
    electionDate,
    type: v.type as VotingMethodType,
  }));

  return { votingMethods, registrationMethods, electionDate };
};

export const Contentful = {
  getContentfulEntries,
  getDWorksResponse,
  getHelpResources,
  getElectionAuthority,
  getVotingAndRegistrationMethods,
  getHelpContent,
};
