import axios, { AxiosResponse } from 'axios';

import { Address } from '../types';
import {
  ElectionsResponse,
  ErrorResponse,
  VoterInfoRequest,
  VotingInfoResponse,
} from '../types/googleCivic';
import { getAddressQuery, parseQueryParams } from '../utils/helpers';

const instance = axios.create({
  baseURL: process.env.REACT_APP_CIVIC_BASE_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_CIVIC_SECRET,
  },
});

const isError = <T>(
  res: AxiosResponse<T> | AxiosResponse<ErrorResponse>,
): res is AxiosResponse<ErrorResponse> =>
  (res as AxiosResponse<ErrorResponse>).data.error !== undefined;

type Response<T> = AxiosResponse<T> | AxiosResponse<ErrorResponse>;

/**
 * Gets all of the elections in the Google Civic API
 *
 * @returns the elections in the API
 */
const getElectionIds = async (): Promise<Response<ElectionsResponse>> =>
  await instance.get(`/elections`);

/**
 * Gets the voter info for an address and election.
 *
 * @param address the address to lookup
 * @param electionId the election id to lookup
 * @returns the voter info for the address and election
 */
const getVoterInfo = async (
  address: Address,
  electionId: string,
): Promise<Response<VotingInfoResponse>> => {
  const addressQueryParam = getAddressQuery(address);

  const params: VoterInfoRequest = {
    address: encodeURI(addressQueryParam),
    electionId: electionId,
  };

  const uri = `/voterinfo?${parseQueryParams(params)}`;

  return await instance.get(uri);
};

export const GoogleCivicAPI = {
  getElectionIds,
  getVoterInfo,
  isError,
};
