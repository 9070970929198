import { Button, Icon, Link, useColorMode } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React, { ReactElement } from 'react';
import { AccessibilityNew } from '@mui/icons-material';

import { NavItem } from '..';
import { OptionsPopover } from '../popover/OptionsPopover';

export const NavDesktopLinks = (): ReactElement => {
  const modeProps = useColorMode();

  return (
    <NavItem variant="default">
      <OptionsPopover>
        <Link
          as={Button}
          alignItems="center"
          fontWeight="bold"
          _hover={{
            textDecoration: 'none',
            backgroundColor: mode(
              'brand.hover.blue',
              'brand.hover.yellow',
            )(modeProps),
          }}
        >
          <Icon
            mr={2}
            textStyle="h3"
            as={AccessibilityNew}
            color={mode('brand.black', 'brand.yellow')(modeProps)}
          />
          Options
        </Link>
      </OptionsPopover>
    </NavItem>
  );
};
