import React, { FC, ReactElement } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import type {
  AccordionProps as ChakraAccordionProps,
  AccordionButtonProps,
} from '@chakra-ui/react';

interface AccordionProps extends ChakraAccordionProps {
  headerText: string;
  panelText: string;
  buttonProps: AccordionButtonProps;
}

// Should we override the svg image with the one in Figma?
export const FAQAccordion: FC<AccordionProps> = ({
  headerText,
  panelText,
  buttonProps,
  ...rest
}): ReactElement => {
  const getFormattedPanelText = () => {
    let formattedText = '';
    panelText.split('\\n').map((text) => {
      formattedText += `${text}` + '\n';
    });
    return formattedText;
  };

  return (
    <Accordion variant="faq" allowMultiple width="100%" {...rest}>
      <AccordionItem>
        <h2>
          <AccordionButton {...buttonProps}>
            <Text textStyle={'h4'} flex="1" textAlign="left">
              {headerText}
            </Text>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>{getFormattedPanelText()}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FAQAccordion;
