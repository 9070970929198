import { Box, Center, Flex, Spinner } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import type { ContainerProps } from '@chakra-ui/react';

import { BreadcrumbBar, ElectionLabel } from '../../components';

interface BallotContainerProps extends ContainerProps {
  includeElectionLabel?: boolean;
  electionId?: number;
  hideBreadcrumbBar?: boolean;
  isLoading?: boolean;
}

export const BallotContainerPage: React.FC<BallotContainerProps> = ({
  children,
  includeElectionLabel,
  electionId,
  hideBreadcrumbBar,
  isLoading,
  ...rest
}): ReactElement => (
  <Box>
    {!hideBreadcrumbBar && <BreadcrumbBar />}
    {includeElectionLabel && (
      <Flex w="100%" padding="25px 0 20px 20px" h="30px">
        <ElectionLabel electionId={electionId} />
      </Flex>
    )}

    <Box
      margin="auto"
      marginTop={['5px', '15px', '50px']}
      width={['90%', '75%', '70%', '55%']}
      {...rest}
    >
      {isLoading ? (
        <Center h="60vh">
          <Spinner size="xl" />
        </Center>
      ) : (
        children
      )}
    </Box>
  </Box>
);

export default BallotContainerPage;
