import { chakra, useTheme, VStack } from '@chakra-ui/react';
import type { StackProps, BoxProps } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';

interface PageProps extends StackProps {
  title: string;
  mainProps?: BoxProps;
  ariaLabel: string;
}

export const Page: FC<PageProps> = ({
  children,
  title,
  id,
  ariaLabel,
  mainProps,
  ...rest
}) => {
  const { sizes } = useTheme();

  useEffect(() => {
    document.title = `${title} | Brink`;
  }, [title]);

  return (
    <>
      <chakra.div
        marginTop={`-${sizes.navHeight}`}
        paddingTop={`${sizes.navHeight}`}
        id="skip-nav"
      />
      <chakra.main
        id={id}
        mt="30px"
        w="100%"
        tabIndex={-1}
        aria-label={ariaLabel}
        {...mainProps}
      >
        <VStack
          alignItems="flex-start"
          spacing="30px"
          m="auto"
          w={['90%', '75%', '60%', '50%']}
          {...rest}
        >
          {children}
        </VStack>
      </chakra.main>
    </>
  );
};

export default Page;
