import axios, { AxiosResponse } from 'axios';

import { parseQueryParams } from '../utils/helpers';
import type { StateAbbreviation } from '../types';
import type {
  PositionsResponse,
  PositionsRequest,
  ElectionRequest,
  ElectionsResponse,
  MeasureRequest,
  MeasureResponse,
  ErrorResponse,
  FullCandidate,
} from '../types/ballotReady';

import {
  getFakeBallotReadyCandidate,
  getFakeBallotReadyElections,
  getFakeBallotReadyMeasures,
  getFakeBallotReadyPositions,
} from './_fakeApi';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BALLOT_READY_BASE_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_BALLOT_READY_SECRET,
  },
});

const USING_FAKE_DATA = false;

const isError = <T>(
  res: AxiosResponse<T> | AxiosResponse<ErrorResponse>,
): res is AxiosResponse<ErrorResponse> => res.status >= 400 && res.status < 500;

type Response<T> = AxiosResponse<T> | AxiosResponse<ErrorResponse>;

/**
 * Gets Ballot ready elections by state
 *
 * @param state The abbreviation of the state to get the elections for.
 * @returns the elections in that state.
 */
const getElectionsByState = async (
  state: StateAbbreviation,
): Promise<Response<ElectionsResponse>> => {
  const params: ElectionRequest = {
    state: state.toLowerCase(),
  };

  const uri = `/elections?${parseQueryParams(params)}`;

  if (USING_FAKE_DATA) {
    return await getFakeBallotReadyElections();
  }

  return await instance.get(uri);
};

/**
 * Gets Ballot ready elections by latitude and longitude.
 *
 * @param lat the latitude of the location to get the elections for.
 * @param long the longitude of the location to get the elections for.
 * @returns the elections in that location.
 */
const getElectionsByLatLong = async (
  lat: number,
  long: number,
): Promise<Response<ElectionsResponse>> => {
  const params: ElectionRequest = {
    latitude: lat,
    longitude: long,
  };

  const uri = `/elections?${parseQueryParams(params)}`;

  if (USING_FAKE_DATA) {
    return await getFakeBallotReadyElections();
  }

  return await instance.get(uri);
};

/**
 * Gets the positions of an election with its candidates.
 *
 * @param lat the latitude of the location to get the positions for.
 * @param long the longitude of the location to get the positions for.
 * @param electionId the id of the election to get the positions for.
 * @returns the positions of the election.
 */
const getPositions = async (
  lat: number,
  long: number,
  electionId: number,
): Promise<Response<PositionsResponse>> => {
  const params: PositionsRequest = {
    lat: lat,
    lon: long,
    election_id: electionId,
    include_candidates: 1,
  };

  const uri = `/positions?${parseQueryParams(params)}`;

  if (USING_FAKE_DATA) {
    return await getFakeBallotReadyPositions();
  }

  return await instance.get(uri);
};

/**
 * Gets the measures of an election.
 *
 * @param lat the latitude of the location to get the measures for.
 * @param long the longitude of the location to get the measures for.
 * @param electionId the id of the election to get the measures for.
 * @returns the measures for the election.
 */
const getMeasures = async (
  lat: number,
  long: number,
  electionId: number,
): Promise<Response<MeasureResponse>> => {
  const params: MeasureRequest = {
    lat: lat,
    lng: long,
    election_id: electionId,
  };

  const uri = `/measures?${parseQueryParams(params)}`;

  if (USING_FAKE_DATA) {
    return await getFakeBallotReadyMeasures();
  }

  return await instance.get(uri);
};

const getCandidateById = async (
  id: number,
  electionId: number | undefined = undefined,
): Promise<Response<FullCandidate>> => {
  const params = {
    electionId,
  };

  const uri = `/candidate/${id}?${parseQueryParams(params)}`;

  if (USING_FAKE_DATA) {
    return await getFakeBallotReadyCandidate();
  }

  return await instance.get(uri);
};

export const BallotReadyAPI = {
  getCandidateById,
  getElectionsByState,
  getElectionsByLatLong,
  getPositions,
  getMeasures,
  isError,
};
