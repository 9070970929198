import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';

import './styles/styles.scss';

import { App } from './app';
import theme from './theme';
import ProviderWrapper from './contexts/AppContext';

console.log(theme);

ReactDOM.render(
  <React.StrictMode>
    <ProviderWrapper>
      <ChakraProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ChakraProvider>
    </ProviderWrapper>
  </React.StrictMode>,

  document.getElementById('root'),
);
