import React, { ReactElement, FC } from 'react';
import {
  HStack,
  Radio,
  Text,
  Link,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { AccessTime, MailOutline, OutlinedFlag } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

import type { VotingMethodData } from '../../types/contentful';
import { useIsMobile } from '../../hooks';
export interface VotingMethodProps extends BoxProps {
  votingType: VotingMethodData;
}

interface VotingTypeValue {
  icon: typeof SvgIcon;
  label: string;
  details: string | null;
  detailsLabel: string;
  selectLabel: string;
}

export const votingTypePairs: Record<string, VotingTypeValue> = {
  'early-voting': {
    icon: AccessTime,
    label: 'Early Voting',
    details: './select/early-voting',
    detailsLabel: 'Learn more about Early Voting',
    selectLabel: 'Select Early Voting',
  },
  'by-mail': {
    icon: MailOutline,
    label: 'Mail-In Ballot',
    details: './select/by-mail',
    detailsLabel: 'Learn more about Voting by Mail',
    selectLabel: 'Select Voting by Mail',
  },
  'in-person': {
    icon: OutlinedFlag,
    label: 'Election Day Voting',
    details: './select/in-person',
    detailsLabel: 'Learn more about In Person Voting',
    selectLabel: 'Select In Person Voting',
  },
};

export const VotingMethodCard: FC<VotingMethodProps> = ({
  votingType,
  ...rest
}): ReactElement => {
  const modeProps = useColorMode();
  const isMobile = useIsMobile();

  return (
    <HStack
      backgroundColor={mode('brand.lightGray', 'black')(modeProps)}
      border="1px solid"
      borderColor={mode('brand.midLightGrey', 'yellow')(modeProps)}
      height="75px"
      width="100%"
      borderRadius="5px"
      paddingLeft="30px"
      paddingRight="30px"
      {...rest}
    >
      <Radio
        size="lg"
        colorScheme="blueScheme"
        spacing="12.5px"
        borderColor="grey"
        value={votingType.type}
        aria-label={votingTypePairs[votingType.type].selectLabel}
        flexGrow={1}
      >
        <Text textStyle="h4">{votingTypePairs[votingType.type].label}</Text>
      </Radio>

      <Button
        justifySelf="center"
        alignSelf="center"
        border="1px"
        color="black"
        borderColor={mode('brand.primary', 'brand.yellow')(modeProps)}
        backgroundColor={mode('white', 'black')(modeProps)}
        as={Link}
        href={votingTypePairs[votingType.type].details || ''}
        aria-label={votingTypePairs[votingType.type].detailsLabel}
      >
        {isMobile ? 'Details' : 'Learn more'}
      </Button>
    </HStack>
  );
};
