import type { Colors } from '@chakra-ui/react';

export const BrinkColors: Colors = {
  brand: {
    primary: '#130067',
    black: '#1A1B22',
    blue: '#3C2ADD',
    red: '#FF0055',
    lightGray: '#F6F6F6',
    darkGray: '#595959',
    midLightGray: '#E2E2E2',
    lightGrey: '#F6F6F6', // same as lightGray - alternative gray spelling
    darkGrey: '#595959', // same as darkGray - alternative gray spelling
    midLightGrey: '#E2E2E2', // same as midLightGray - alternative gray spelling
    base: '#EDEBF5',
    lightBase: '#F8FAFF',
    darkBase: '#D6D6E8',
    yellow: '#FFFF00',
    hover: {
      blue: 'rgba(60, 42, 221, 0.5)',
      yellow: '#4B4B00',
    },
    scheme: {
      yellow: {
        50: '#ffffda',
        100: '#ffffad',
        200: '#ffff7d',
        300: '#ffff4b',
        400: '#ffff1a',
        500: '#e5e600',
        600: '#b2b300',
        700: '#7f8000',
        800: '#4c4d00',
        900: '#191b00',
      },
    },
  },
  party: {
    democratic: '#244998',
    republican: '#D12531',
    green: '#2D6B00',
    libertarian: '#816900',
  },
};
