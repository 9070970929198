import { List } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import BallotListWrapper from '../../components/ballot/BallotListWrapper';
import BallotTitle from '../../components/ballot/BallotTitle';
import { CandidateGrid } from '../../components/ballot/CandidateGrid';
import { useBallotData } from '../../contexts';
import { capitalize } from '../../utils/helpers';

import BallotContainer from './BallotContainerPage';

export const CandidatesPage = (): ReactElement => {
  const { pathname } = useLocation();
  // TODO: Handle error checking in case this regex match messes up
  const positionName = pathname.split('/').pop();
  const parsedName = positionName && decodeURI(positionName).toLowerCase();

  const { positions, isLoading } = useBallotData();
  const electionId = Number(
    window.location.href.match(/\/ballot\/(\d+)\/.*/)?.[1],
  );

  const level =
    positions[electionId]?.length > 0 && positions[electionId][0].level;

  const correspondingCandidates = positions[electionId]?.find(
    (position) => position.name.toLowerCase() === parsedName,
  )?.candidates;

  return (
    <BallotContainer minW="80%" includeElectionLabel isLoading={isLoading}>
      {correspondingCandidates && (
        <BallotTitle
          name={positionName ? capitalize(decodeURI(positionName)) : ''}
          subheading="Select a candidate to learn more and add a candidate to your favorites."
          display="center"
        />
      )}
      <List w="100%">
        {correspondingCandidates && (
          <BallotListWrapper
            length={correspondingCandidates?.length}
            countDescriptor="candidates"
          >
            <CandidateGrid
              candidateBioPath={`home/ballot/${electionId}/${level
                .toString()
                .toLowerCase()}/${positionName}/candidate`}
              candidates={correspondingCandidates}
            />
          </BallotListWrapper>
        )}
      </List>
    </BallotContainer>
  );
};
export default CandidatesPage;
