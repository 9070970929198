import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Box, Spinner, Center } from '@chakra-ui/react';

import {
  LandingPage,
  HelpPage,
  HomepageWrapper,
  CandidatesPage,
  PositionsPage,
  VoterFAQPage,
  RacesPage,
  PollingPlaceIssuePage,
  VotingMethodSelectionPage,
  VotingMethodInfoPage,
  FavoritesPage,
  ElectionChecklistPage,
  DefaultChecklistPage,
  CandidatePage,
} from './pages';
import { Navbar } from './layout';
import { useBallotData } from './contexts';
import { SkipContentLink } from './components';

export const App = () => {
  const { elections, isLoading } = useBallotData();

  const getChecklistPage = () => {
    if (isLoading) {
      return (
        <Center height="80vh">
          <Spinner size="xl" />
        </Center>
      );
    }
    return elections.length > 0 ? (
      <ElectionChecklistPage />
    ) : (
      <DefaultChecklistPage />
    );
  };

  return (
    <Box>
      <SkipContentLink text="Skip Main Navigation" href="#skip-nav" />
      <Navbar />
      <Routes>
        <Route path={'/landing'} element={<LandingPage />} />
        <Route
          path={'/home/help/polling/:id'}
          element={<PollingPlaceIssuePage />}
        />
        <Route
          path={'/donate'}
          element={<h1>This will be the donate page</h1>}
        />
        <Route
          path={'/home/help'}
          element={<HomepageWrapper page={<HelpPage />} />}
        />
        <Route
          path={'/donate'}
          element={<h1>This will be the donate page</h1>}
        />
        <Route
          path={'/home/select'}
          element={<HomepageWrapper page={<VotingMethodSelectionPage />} />}
        />
        <Route
          path={'/home/checklist'}
          element={<HomepageWrapper page={getChecklistPage()} />}
        />
        <Route
          path={'/home/faq'}
          element={<HomepageWrapper page={<VoterFAQPage />} />}
        />
        <Route
          path={
            '/home/ballot/:electionId/:level/:electionName/candidate/:candidateId'
          }
          element={<HomepageWrapper page={<CandidatePage />} />}
        />
        <Route
          path={'/home/ballot'}
          element={<HomepageWrapper page={<RacesPage />} />}
        />
        <Route
          path={'/home/ballot/favorites'}
          element={<HomepageWrapper page={<FavoritesPage />} />}
        />
        <Route
          path={'/home/ballot/:electionId/:level'}
          element={<HomepageWrapper page={<PositionsPage />} />}
        />
        <Route
          path={'/home/ballot/:electionId/:level/:electionName'}
          element={<HomepageWrapper page={<CandidatesPage />} />}
        />
        {/* <Route path="*" element={<Navigate to={'/home/checklist'} />} /> */}
        <Route
          path={'/accessibility'}
          element={<h1>This will be the accessibility page</h1>}
        />
        <Route
          path={'/home/select/:votingMethod'}
          element={<HomepageWrapper page={<VotingMethodInfoPage />} />}
        />

        <Route path="*" element={<Navigate to={'/landing'} />} />
      </Routes>
    </Box>
  );
};
export default App;
