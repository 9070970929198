import { ListItem } from '@chakra-ui/react';
import React, { FC } from 'react';

import BallotLink from '../link/BallotLink';

import { HelpSection } from './HelpSection';

const items = (state: string, zipcode: string) => [
  {
    href: `https://www.surveymonkey.com/r/6Z59R7Q?firebase_id=INSERTID&state=${state}&zip=${zipcode}`,
    title: 'Provide feedback to Brink',
  },
  {
    href: 'https://www.brinkapp.co/privacy-policy',
    title: 'Privacy Policy',
  },
  {
    href: 'https://www.brinkapp.co/end-user-license-agreement',
    title: 'Terms of Service',
  },
];

interface HelpAboutSectionProps {
  state?: string;
  zip?: string;
}

export const HelpAboutSection: FC<HelpAboutSectionProps> = ({
  state = '',
  zip = '',
}) => {
  const aboutItmes = items(state, zip);

  return (
    <HelpSection
      title="Resolve polling place issues"
      items={aboutItmes.map((item) => (
        <ListItem key={item.title}>
          <BallotLink
            href={item.href}
            width="100%"
            isTruncated={false}
            padding={['4', '5', '6']}
            maxHeight="fit-content"
            mt={3}
            mb={3}
            isExternal
          >
            {item.title}
          </BallotLink>
        </ListItem>
      ))}
    />
  );
};

export default HelpAboutSection;
