import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Text,
  Spinner,
  Box,
  Flex,
  Link,
  Heading,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { useNavigate, useParams } from 'react-router-dom';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from 'react-markdown';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { HelpContent } from '../../types/contentful';
import { Contentful } from '../../api';
import { isNumber } from '../../utils/helpers';
import { useIsMobile } from '../../hooks';
import { markdownTheme } from '../../markdown';

const getIssueIndex = (id: string): number => {
  if (!isNumber(id)) {
    return 0;
  }
  const value = Number(id);

  return value < 0 ? 0 : value;
};

export const PollingPlaceIssuePage: FC = (): ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pollingIssues, setPollingIssues] = useState<HelpContent[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [noIssueFound, setNoIssueFound] = useState(false);
  const isMobile = useIsMobile();

  const { sizes } = useTheme();

  const modeProps = useColorMode();

  const getBorderColor = () => {
    if (modeProps.colorMode === 'dark') {
      return 'brand.yellow';
    }
  };

  const [previousText, nextText] = useMemo(() => {
    if (isMobile) {
      return ['Previous', 'Next'];
    }
    return ['Previous Issue', 'Next Issue'];
  }, [isMobile]);

  const index = getIssueIndex(id ?? '0');

  useEffect(() => {
    document.title = 'Polling Place Issue | Brink';
  }, []);

  useEffect(() => {
    // TODO: How do we indicate loading states for the API in a way that is accessible?
    const loadHelpContent = async (index: number) => {
      const pollingIssues = await Contentful.getHelpContent('voting_right');
      // TODO: Should it throw an error if it doesn't work when trying to get FAQ information
      if (pollingIssues.length <= index || index < 0) {
        setNoIssueFound(true);
      } else {
        setPollingIssues(pollingIssues);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    if (`${index}` !== id) {
      navigate(`/home/help/polling/${index}`);
    } else {
      loadHelpContent(index);
    }
  }, [id, index, navigate]);

  if (isLoading) {
    return <Spinner m={1} />;
  } else if (noIssueFound) {
    return (
      <Text as="h1" textStyle="h1">
        No Issue Found
      </Text>
    );
  }

  const white = mode('white', 'black')(modeProps);
  const blue = mode('blue', 'brand.yellow')(modeProps);

  return (
    <main>
      <Box backgroundColor={white} mb={`calc(${sizes.navHeight} + 10px)`}>
        <Flex
          width="100vw"
          background={white}
          top={sizes.navHeight}
          height="8vh"
          position="sticky"
          alignItems="center"
        >
          <Link
            position="relative"
            fontWeight="bold"
            color={blue}
            border={mode('none', '1px solid')(modeProps)}
            borderColor={getBorderColor()}
            left={['3', '10']}
            href="/home/help"
            padding="5px"
            background="none"
          >
            <ChevronLeftIcon w={4} h={4} mr={2} mb={1} />
            Back to Help
          </Link>
        </Flex>
        <Box backgroundColor={white} w={['90%', '60%', '50%']} mt={6} mx="auto">
          <Heading as="h1" my={6} textStyle="h1" fontSize={['28px', '32px']}>
            Polling Place Issue
          </Heading>
          <ReactMarkdown skipHtml components={ChakraUIRenderer(markdownTheme)}>
            {pollingIssues![index].body}
          </ReactMarkdown>
        </Box>
        <Flex
          position="fixed"
          top="calc(100vh * 0.9)"
          left={['3', '10']}
          right={['3', '10']}
          background={white}
          height="calc(100vh * 0.1)"
        >
          {index > 0 && (
            <Link
              fontWeight="bold"
              color={blue}
              bg={white}
              href={`${index - 1}`}
              alignSelf="center"
              border={mode('none', '1px')(modeProps)}
              borderColor={getBorderColor()}
              padding="5px"
            >
              <ChevronLeftIcon w={4} h={4} mr={2} mb={1} />
              {previousText}
            </Link>
          )}
          <Flex flexGrow={1} />
          {index < pollingIssues!.length - 1 && (
            <Link
              fontWeight="bold"
              color={blue}
              bg={white}
              href={`${index + 1}`}
              alignSelf="center"
              border={mode('none', '1px')(modeProps)}
              borderColor={getBorderColor()}
              padding="5px"
            >
              {nextText}
              <ChevronRightIcon w={4} h={4} ml={2} mb={0.5} />
            </Link>
          )}
        </Flex>
      </Box>
    </main>
  );
};
