import React, { FC, ReactElement, useEffect, useState } from 'react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import type { AutoCompleteProps } from '@choc-ui/chakra-autocomplete';
import {
  Alert,
  AlertIcon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorMode,
} from '@chakra-ui/react';
import MicIcon from '@mui/icons-material/Mic';
import SearchIcon from '@mui/icons-material/Search';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import type { GeocodeResult } from 'use-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import { mode } from '@chakra-ui/theme-tools';
import { useNavigate } from 'react-router-dom';

import { useSavedAddress } from '../../contexts/AddressContext';
import { StateAbbreviation } from '../../types';
import { useChecklist, useVotingMethod } from '../../contexts';

interface SearchAddressProps extends Omit<AutoCompleteProps, 'children'> {
  id: string;
}

export const SearchAddress: FC<SearchAddressProps> = ({
  id,
  ...rest
}): ReactElement => {
  const modeProps = useColorMode();

  const [isFocused, setIsFocused] = useState(true);
  const [didSelect, setDidSelect] = useState(false);
  const [error, setError] = useState(false);
  const { setVotingMethod } = useVotingMethod();
  const { dispatch } = useChecklist();

  const navigate = useNavigate();
  const { address, setAddress } = useSavedAddress();

  // TODO: Figure out correct type
  const googleMapLoader = (GoogleMapReact as any).googleMapLoader;
  const {
    init,
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 200,
    defaultValue: address?.fullAddress,
  });

  useEffect(() => {
    googleMapLoader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'beta',
      libraries: ['places'],
    }).then(init);
  }, [googleMapLoader, init]);

  useEffect(() => {
    if (didSelect) {
      navigate('/home/checklist');
    }
  }, [didSelect, address, navigate]);

  const getZipCode = (result: GeocodeResult) => {
    const zipCode = result.address_components.find((component) =>
      component.types.includes('postal_code'),
    );

    if (!zipCode?.long_name) {
      console.error('No zip code found');
      return '';
    }

    return zipCode.long_name;
  };

  const getStateAbbr = (
    address: GeocodeResult['address_components'],
  ): string => {
    const state = address.find((component) =>
      component.types.includes('administrative_area_level_1'),
    );

    return state?.short_name ?? '';
  };

  const getCountryAbbr = (
    address: GeocodeResult['address_components'],
  ): string => {
    const country = address.find((component) =>
      component.types.includes('country'),
    );

    return country?.short_name ?? '';
  };

  const getOcdId = (
    address: GeocodeResult['address_components'],
  ): string | null => {
    const stateAbbr = getStateAbbr(address);

    const countryAbbr = getCountryAbbr(address);

    if (countryAbbr.length === 0 || stateAbbr.length === 0) {
      return null;
    } else if (countryAbbr !== 'US') {
      return null;
    }

    return `ocd-division/country:${countryAbbr.toLowerCase()}/state:${stateAbbr.toLowerCase()}`;
  };

  const onAddressSubmit = (value: string) => {
    const loadLatLong = async (): Promise<void> => {
      const geoRes = await getGeocode({ address: value });
      const { lat, lng } = await getLatLng(geoRes[0]);

      const ocdId = getOcdId(geoRes[0].address_components);

      if (!ocdId) {
        setError(true);
        return;
      }

      setAddress({
        fullAddress: value,
        lat,
        lng,
        ocdId,
        state: getStateAbbr(geoRes[0].address_components) as StateAbbreviation,
        zipcode: getZipCode(geoRes[0]),
      });

      setError(false);
      setVotingMethod(null);
      dispatch({ type: 'clear', id: null });
      setDidSelect(true);
    };

    loadLatLong();
  };

  return (
    <AutoComplete
      {...rest}
      rollNavigation
      closeOnBlur={false}
      shouldRenderSuggestions={() => ready}
      onSelectOption={({ item }) => onAddressSubmit(item.value)}
    >
      <InputGroup size="lg" backgroundColor={mode('white', 'black')(modeProps)}>
        <InputLeftElement
          pointerEvents="none"
          color={isFocused ? 'grey' : 'blue'}
        >
          <SearchIcon />
        </InputLeftElement>
        <AutoCompleteInput
          id={id}
          color={modeProps.colorMode === 'light' ? 'black' : 'green'}
          placeholder="Your address, e.g. 123 Street, City"
          borderColor="grey"
          boxShadow="base"
          borderWidth="1px"
          _hover={{
            borderColor: 'grey',
            boxShadow: 'lg',
          }}
          _focus={{
            borderColor: 'blue',
            boxShadow: 'base',
          }}
          onChange={(e) => setValue(e.target.value)}
          onFocus={() => setIsFocused(!isFocused)}
          onBlur={() => setIsFocused(!isFocused)}
          value={value}
          autoComplete="off"
        />
        <InputRightElement color="grey">
          <MicIcon />
        </InputRightElement>
      </InputGroup>
      {error && (
        <Alert mt={5} variant="subtle" status="error">
          <AlertIcon />
          Please enter a valid address. Must be a state in the United States.
        </Alert>
      )}
      {status === 'OK' && (
        <AutoCompleteList m={0} borderRadius="0">
          {data.map(({ description, place_id }) => (
            <AutoCompleteItem
              _hover={{ background: 'baseLight' }}
              pl="15px"
              m={0}
              key={place_id}
              value={description}
              borderRadius="none"
            >
              {description}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      )}
    </AutoComplete>
  );
};

export default SearchAddress;
