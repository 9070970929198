import { Address } from '../types';

export const getLocalityName = (address: Address): string | null => {
  const { locality, sublocality, sublocality_level_1 } = address;

  if (locality) {
    return locality.long_name;
  } else if (sublocality) {
    return sublocality.long_name;
  } else if (sublocality_level_1) {
    return sublocality_level_1.long_name;
  }

  return null;
};

export const getAddressQuery = (address: Address): string => {
  const { street_number, route, postal_code, administrative_area_level_1 } =
    address;
  const localityName = getLocalityName(address);

  const street = `${street_number && street_number.short_name} ${
    route && route.short_name
  }`;
  const cityStateZip = `${localityName && localityName}, ${
    administrative_area_level_1 && administrative_area_level_1.short_name
  } ${postal_code && postal_code.long_name}`;

  return `${street}, ${cityStateZip}`;
};

export const parseQueryParams = (
  params: Record<string | number, unknown>,
): string =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const isNumber = (value: any): value is number => {
  if (typeof value === 'string' && !Number.isNaN(Number(value))) {
    return true;
  }

  return false;
};

export const formatDate = (date: string) => {
  const d = new Date(date);

  const isoString = d.toISOString();

  const year = Number(isoString.substring(0, 4));
  const month = Number(isoString.substring(5, 7));
  const day = Number(isoString.substring(8, 10));

  const fullMonth = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][month];

  return `${fullMonth} ${day}, ${year}`;
};

export const formatDay = (day: number): string => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][day];

  return days;
};

export const capitalize = (str: string): string =>
  str
    .split(' ')
    .map((word) => {
      if (word.indexOf('.') > 0) {
        return word.toUpperCase();
      } else if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(' ');
